import { graphql } from 'gatsby';
import React from 'react'
import Navbar from '../components/Navbar/Navbar'

export default function blogDetails({data}) {
    const datas  = data.markdownRemark;
    return (
        <div>
            <Navbar color="black" />
            <div className="blog-detials-page">
                <h1 className="bold head-title">{datas.frontmatter.title}</h1>
                <div>
                <span className="thin right-margin">{datas.frontmatter.date}</span>
                <span className="thin">{datas.frontmatter.readMin}</span>
                </div>
                <div className="featured">
                    <img src={datas.frontmatter.Banner.childImageSharp.gatsbyImageData.images.fallback.src} alt="featured"/>
                </div>
                <div className="html" dangerouslySetInnerHTML={{__html:datas.html}} />
            </div>
        </div>
    )
}


export const  query = graphql`
query blogSection($url: String) {
    markdownRemark(frontmatter: {url: {eq: $url}}) {
      id
      html
      frontmatter {
        readMin
        title
        date
        BannerText
        Banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: NO_CHANGE)
          }
        }
      }
    }
  }

`